import styled from "styled-components";
import { respondTo } from "../../styles/respondTo";
import OctopusPlay from "../../images/PMB_octopus_play.png";
import { PageHeaderImage } from "../common/PageHeaderImage";

export const StartPagePlayLink = styled.a`
  ${PageHeaderImage} {
    ${respondTo.md`
      background-image: url(${OctopusPlay});
      transition: transform 0.3s, filter 0.3s;

      &:hover {
        transform: scale(1.02);
        filter: drop-shadow(12px 11px 8px rgba(0, 0, 0, 0.1));
      }
    `}
  }
`;
