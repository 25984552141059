import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { toHTML } from "../../utils/helpers/index";
import { respondTo } from "../../styles";
import {
  TextEditor,
  PageSection,
  PageSectionArticle,
  PageSectionBackdrop,
} from "../common";
import { ImageWrapperRectangle } from "../common/ImageWrapper";

const StartPagePressContainer = styled.div`
  display: flex;
  flex-flow: column wrap;

  ${respondTo.lg`
    flex-direction: row;
    margin-bottom: 50px;
  `}
`;

const StartPagePressBox = styled.div`
 // font-size: 14px;
 // font-weight: 700;
  margin-bottom: 30px;

  ${respondTo.lg`
    margin-bottom: 0;
    width: 33.33333333333%;
    padding-right: 26px;
  `}

  p {
    margin-bottom: 12px;
    font-style: normal;
  }
  ${ImageWrapperRectangle} {
    margin-top: 45px;
    margin-bottom: 0px;
  }
`;

const PressDownloadLink = styled.a`
//  display: flex;
//  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 20px;
`;

export const StartPagePress = ({ startPress }) => {
  const { heading, startPressFileList, startPressFileName } = startPress;
  return (
    <PageSection bgColor="var(--sandBeige)">
      <PageSectionBackdrop bgColor="var(--sandBeige)">
        <PageSectionArticle>
          <h2>{heading}</h2>
          <StartPagePressContainer>
            {!!startPressFileList &&
              startPressFileList.map(({ startPressFileName, startPressFile, startPressImg }, i) => (
                <StartPagePressBox key={i}>
                  <PressDownloadLink
                            href={startPressFile.publicURL}
                            target="_blank"
                            rel="noopener noreferrer"
                  >
                    <ImageWrapperRectangle>
                      <Img 
                        fluid={
                          startPressImg.childImageSharp
                            .fluid
                        } 
                      />
                    </ImageWrapperRectangle>
                  </PressDownloadLink>
                    <p>
                      <TextEditor dangerouslySetInnerHTML={{ __html: toHTML(startPressFileName) }} />
                    </p>
                </StartPagePressBox>
              ))}
          </StartPagePressContainer>
        </PageSectionArticle>
      </PageSectionBackdrop>
    </PageSection>
  );
};
