import React from "react";
import styled from "styled-components";
import {
  PageSectionHeader,
  PageSectionHeaderText,
  TextEditor,
} from "../common";
import { PageHeaderImage } from "../common/PageHeaderImage";
import { StartPagePlayLink } from "./StartPagePlayLink";
import { toHTML } from "../../utils/helpers/index";
import { respondTo } from "../../styles";

const StartPageHeaderText = styled(PageSectionHeaderText)`
  padding-top: 40px;

  h1 {
    max-width: 480px;
  }

  ${respondTo.lg`
    padding-top: 0;
  `}
`;

export const StartPageHeader = ({ startHeader }) => {
  const { heading, body } = startHeader;
  return (
    <PageSectionHeader>
      <StartPagePlayLink href="/play" target="_blank" rel="noopener noreferrer">
        <PageHeaderImage />
        <span className="sr-only">Till spelet</span>
      </StartPagePlayLink>
      <StartPageHeaderText>
        <h1>{heading}</h1>
        <TextEditor dangerouslySetInnerHTML={{ __html: toHTML(body) }} />
      </StartPageHeaderText>
    </PageSectionHeader>
  );
};
