import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import StartBg from "../images/PMB_framsida_clouds.png";
import {
  StartPageHeader,
  StartPageIntro,
  StartPageTestimonials,
  StartPagePress,
} from "../components/StartPage";
import SEO from "../components/SEO";
import {
  PageSection,
  PageSectionBackdrop,
  PageSectionWrapper,
} from "../components/common";
import { respondTo } from "../styles";

const StartPageTopSection = styled(PageSectionBackdrop)`
  background-image: url(${StartBg});
  background-repeat: no-repeat;
  background-size: 100% 300px;

  ${respondTo.md`
    background-size: 100% 450px;
  `}

  ${respondTo.lg`
    background-size: 100% 650px;
  `}
`;

const isBrowser = typeof window !== "undefined";

if (isBrowser) {
  const paths = ["play", "forgotPassword", "login", "account", "gdpr", "privacy-policy", "localhost", "user-admin"];
  
  if (!paths.some(path => window.location.href.includes(path))) {
    window.location.replace("https://www.mappia.se");
  } else {
    console.log("stay on page");
  }
}

const StartPageTemplate = ({
  title,
  startHeader,
  startIntro,
  startTestimonials,
  startPress,
}) => {
  return (
    <>
      <SEO title={title} />
      <PageSectionWrapper>
        <PageSection bgColor="var(--sandBeige)">
          <StartPageTopSection bgColor="var(--sandBeige)">
            <StartPageHeader startHeader={startHeader} />
            <StartPageIntro startIntro={startIntro} />
          </StartPageTopSection>
        </PageSection>
        <StartPageTestimonials startTestimonials={startTestimonials} />
        <StartPagePress startPress={startPress} />
      </PageSectionWrapper>
    </>
  );
};

const StartPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <StartPageTemplate
      title={frontmatter.title}
      startHeader={frontmatter.startHeader}
      startTestimonials={frontmatter.startTestimonials}
      startPress={frontmatter.startPress}
      startIntro={frontmatter.startIntro}
    />
  );
};

export default StartPage;

export const pageQuery = graphql`
  query StartPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "start-page" } }) {
      frontmatter {
        title
        startHeader {
          heading
          body
        }
        startIntro {
          heading
          body
        }
        startTestimonials {
          heading
          startTestimonialsList {
            body
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            altText
          }
        }
        startPress {
          heading
          startPressFileList {
            startPressFileName
            startPressFile {
              publicURL
              name
            }
            startPressImg {
              childImageSharp {
                fluid(maxWidth: 300, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
